@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap");

body {
  font-family: "Work Sans", sans-serif !important;
  font-size: 15px;
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 24px;
}
